<template>
    <div class="d-flex align-items-center justify-content-center">
        <b-button class="d-flex justify-content-center align-items-center" :variant="plan.variant" @click="handleClick">
            <span class="f-14">{{ plan.display }}</span>
        </b-button>
        <div class="ml-3 w-75 description">
            <p class="mb-0 f-13">{{ plan.description }}</p>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    props: {
        plan: {
            type: Object,
            default: () => null,
        },
    },
    computed: {
        ...mapGetters({ plan_category: 'subscription/planCategory' }),
    },
    methods: {
        handleClick() {
            this.plan_category === this.plan.type ? this.resumePlan() : this.changePlan()
        },
        changePlan() {
            this.$emit('change-plan', {
                plan: this.plan.type,
                downgrade: this.plan.downgrade,
                desc: this.plan.downgrade_desc,
            })
        },
        resumePlan() {
            this.$emit('resume-plan')
        },
    },
})
</script>

<style scoped>
.btn {
    min-width: 13rem;
}
.description p {
    line-height: 21.79px;
}
</style>
